
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "build": {
    "user": "root",
    "host": "61740def2112",
    "time": "2024/08/30 22:21:53",
    "commit": "538fbb7c5bc5f696bb36af2b4fbef43a45264c46",
    "repo": "git@bitbucket.org:rscdevelopers/vv-public.git",
    "branch": "master",
    "release": "538fbb7c5bc5f696bb36af2b4fbef43a45264c46"
  },
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
